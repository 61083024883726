import React from "react"
import { graphql, Link } from "gatsby"

import Article from '../../components/Article'
import SEO from '../../components/seo'

import BackArrow from '../../assets/arrow.svg'

// Styles
import * as style from './index.module.css'

export default function Template({
	comments,
	data,
	isBraveBrowser,
	likes,
	setComment,
	setLike,
	supportsPush,
}) {
	const { markdownRemark } = data // data.markdownRemark holds your post data

	if (!markdownRemark) { return null }

	const { frontmatter, html, plainText } = markdownRemark

	const articleLikes = likes ? likes.filter(like => like.filmId === frontmatter.index) : []

	return (
		<>
			<SEO
				image={ `https://besthorrorscenes.com/posters/${frontmatter.poster}` }
				postContent={ plainText || null }
				title={ frontmatter.title }
				url={ `https://besthorrorscenes.com${frontmatter.path}` }
				year={ frontmatter.year }
			/>
			<nav className={ style.ArticleNav }>
				<Link className={ style.BackLink } to="/">Home</Link>
			</nav>
			<Article
				standalone
				comments={ comments }
				content={ html }
				director={ frontmatter.director }
				entryNumber={ frontmatter.index }
				index={ 10 }
				isBraveBrowser={ isBraveBrowser }
				likes={ articleLikes }
				poster={ frontmatter.poster }
				rating={ frontmatter.rating }
				setComment={ setComment }
				setLike={ setLike }
				slug={ frontmatter.path }
				supportsPush={ supportsPush }
				suggestedBy={ frontmatter.suggestedBy }
				title={ frontmatter.title }
				url={ frontmatter.url }
				year={ frontmatter.year }
			/>
		</>
	)
}

export const query = graphql`
	query($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			frontmatter {
				director
				index
				path
				poster
				rating
				suggestedBy
				title
				url
				year
			}
			html
			plainText
		}
	}
`
